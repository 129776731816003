import {
  RoomCommands,
  PanelCommands,
  BindingCommands,
  SystemCommands,
  RFCommands,
  buildCommand,
} from "../utils/CommandsUtils";

export class CommandService {
  private serialService: any;

  constructor(serialService: any) {
    this.serialService = serialService;
  }

  // Room-related commands
  async getRooms(): Promise<void> {
    await this.serialService.write(RoomCommands.GetRooms);
  }

  async deleteRoom(id: string, name: string): Promise<void> {
    await this.serialService.write(
      buildCommand(RoomCommands.DeleteRoom, id, name)
    );
  }

  async addRoom(roomName: string): Promise<void> {
    await this.serialService.write(
      buildCommand(RoomCommands.AddRoom, roomName)
    );
  }

  async pairRoom(
    roomId: string,
    roomName: string,
    panelId?: string | null
  ): Promise<void> {
    await this.serialService.write(
      buildCommand(RoomCommands.PairRoom, roomId, roomName, panelId)
    );
  }

  // Panel-related commands
  async getConfiguredPanels(): Promise<void> {
    await this.serialService.write(PanelCommands.GetAll);
  }

  async removePanelInRoom(id: string, nm: string, dt: string): Promise<void> {
    await this.serialService.write(
      buildCommand(PanelCommands.RemovePanelInRoom, id, nm, dt)
    );
  }
  async panelInfo(panelId: string): Promise<void> {
    await this.serialService.write(
      buildCommand(PanelCommands.GetPanelInfo, panelId)
    );
  }

  async getPanelStatus(): Promise<void> {
    await this.serialService.write(PanelCommands.GetStatus);
  }

  async getGatewayInfo(): Promise<void> {
    await this.serialService.write(PanelCommands.GetGatewayInfo);
  }

  async renamePanel(
    panelId: string | undefined,
    newName: string
  ): Promise<void> {
    await this.serialService.write(
      buildCommand(PanelCommands.RenamePanel, panelId, newName)
    );
  }

  // Binding--related commands
  async getBindingDetails(macId: string, btnNo: string): Promise<void> {
    await this.serialService.write(
      buildCommand(BindingCommands.GetDetails, macId, btnNo)
    );
  }

  async removeBinding(panelId: string, btnNo: string): Promise<void> {
    await this.serialService.write(
      buildCommand(BindingCommands.RemoveBinding, panelId, btnNo)
    );
  }
  async setTwoWayBinding(
    panelId: string,
    number: string,
    dynamicBindings: any
  ): Promise<void> {
    await this.serialService.write(
      buildCommand(
        BindingCommands.SetTwoWayBinding,
        panelId,
        number,
        dynamicBindings
      )
    );
  }
  // System---related commands
  async addProjectName(projectName: string): Promise<void> {
    await this.serialService.write(
      buildCommand(SystemCommands.SetProjectName, projectName)
    );
  }

  async searchOn(): Promise<void> {
    await this.serialService.write(SystemCommands.SearchOn);
  }
  async searchOff(): Promise<void> {
    await this.serialService.write(SystemCommands.SearchOff);
  }

  // RF---related commands
  async sendRFCommand(
    macId: string,
    btnNo: string,
    value: number,
    state: "on" | "off"
  ): Promise<void> {
    await this.serialService.write(
      buildCommand(RFCommands.Send, macId, state, btnNo, value)
    );
  }
}
