import React from "react";
import CustomInput from "./inputs/CustomInput";
import Loader from "../constants/loader";
interface IProjectNameDialog {
  setProjectName: (value: string) => void;
  projectName: string;
  handleCloseProjectNameDialog: () => void;
  handleSubmitProjectName: () => void;
  title?: string;
  loading?: boolean;
  showCloseButton?: boolean;
  errorMessage?: string;
}
function ProjectNameDialog({
  setProjectName,
  projectName,
  handleCloseProjectNameDialog,
  handleSubmitProjectName,
  title = "Project Name",
  loading = false,
  showCloseButton = true,
  errorMessage,
}: IProjectNameDialog) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-2 z-50">
      <div className="bg-white relative p-6 rounded-lg shadow-lg w-96 ">
        <div className="border-b mt-6">
          <h2 className="text-xl font-semibold mb-2  pb-2">{title}</h2>
        </div>
        {showCloseButton && (
          <button
            className="absolute top-1 right-2 text-red-500 font-bold text-lg"
            onClick={() => handleCloseProjectNameDialog()}
          >
            ×
          </button>
        )}

        <div className="flex">
          {loading && (
            <Loader position="absolute top-[50%]  -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" />
          )}
          <>
            <CustomInput
              className="mt-4"
              label=" Name"
              placeholder="Enter your name..."
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
            <div className="flex items-center pt-9 pl-4">
              <button
                className="text-white bg-blue-300 hover:bg-blue-800 focus:ring-1 focus:ring-blue-300 font-medium rounded-lg text-xs  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-2 py-2"
                onClick={() => handleSubmitProjectName()}
                disabled={loading}
              >
                Submit
              </button>
            </div>
          </>
          {errorMessage && (
            <span className="text-red-500 self-center items-center">
              {errorMessage}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProjectNameDialog;
