import React from "react";

interface TextInputProps {
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const CustomInput: React.FC<TextInputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  className,
}) => {
  return (
    <div className={`w-full max-w-sm min-w-[200px] ${className}`}>
      {label && (
        <label className="block text-slate-700 text-sm font-medium mb-1">
          {label}
        </label>
      )}
      <input
        className="w-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow"
        placeholder={placeholder || "Type here..."}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default CustomInput;
