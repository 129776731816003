// Commands.enum.ts
export enum RoomCommands {
  GetRooms = "req<roomlist>\n\r",
  DeleteRoom = "cmd<deleteroom,${id},${name}>",
  AddRoom = "cmd<addroom,${roomName}>", //nd
  PairRoom = "cmd<pair,${roomId},${roomName},${panelId}>",
}

export enum PanelCommands {
  GetAll = "getallpanels\r\n",
  GetStatus = "req<getstatus>",
  GetGatewayInfo = "req<getwayInfo>",
  RenamePanel = "cmd<pname,${panelId}${newName}>",
  RemovePanelInRoom = "cmd<remove,${id},${nm},${dt}>",
  GetPanelInfo = "cmd<getinfo,${panelId}>",
}

export enum BindingCommands {
  GetDetails = "cmd<bidetail,${macId}:${btnNo}>",
  RemoveBinding = "cmd<birm,${panelId}:${btnNo}>",
  SetTwoWayBinding = "cmd<binding,${panelId}:${number},${dynamicBindings}>",
}

export enum SystemCommands {
  SetProjectName = "cmd<name,${name}>",
  SearchOn = "req<searchon>",
  SearchOff = "req<searchoff>",
}

export enum RFCommands {
  Send = "sendrf,ucast,${macId},<req,${state},${btnNo},${value},null>\r\n",
}

export function buildCommand<T extends string>(
  template: T,
  ...values: any[]
): string {
  let i = 0;
  return template.replace(/\$\{(\w+)\}/g, () => values[i++] || "");
}
