import { useContext, useState } from "react";
import ToggleSwitch from "./toggleSwitch";
import { SerialServiceContext } from "..";
import Loader from "../constants/loader";
interface IMainComponent {
  getConfiguredPanels: () => void;
  configuredList: string[];
  parableList: string[];
  commands: string[];
  isConnected: boolean;
  setCommands: any;
  handleGetInfoPanel: (panelId: string, panelName: string) => void;
  serialService: any;
  connectionLoadingStates: boolean;
  gateWayName: string;
  handlePairPanel: (panel: string) => void;
  handlePairDialog: (panel: string) => void;
  handleRemoveRooms?: (item: { id: string; nm: string }) => void;
  handleRemovePanelFromRoom?: any;
  setAddRoomDialog?: any;
  errorMessage?: string;
  roomsList?: string[];
  setSelectedRoomId: any;
  selectedRoomId: string;
  removeRoomsLoader: boolean;
  setRoomName: (value: string) => void;
  handleSearchOn: () => void;
  handleSearchOff: () => void;
}
function MainComponent({
  getConfiguredPanels,
  configuredList,
  parableList,
  isConnected,
  commands,
  setCommands,
  handleGetInfoPanel,
  serialService,
  connectionLoadingStates,
  gateWayName,
  handlePairPanel,
  handleRemovePanelFromRoom,
  handlePairDialog,
  setAddRoomDialog,
  errorMessage,
  roomsList,
  setSelectedRoomId,
  handleRemoveRooms,
  selectedRoomId,
  removeRoomsLoader,
  setRoomName,
  handleSearchOn,
  handleSearchOff,
}: IMainComponent) {
  const [isScanning, setIsScanning] = useState(false);
  const [isCommandShow, setIsCommandShow] = useState(false);
  const handleCommandShow = () => {
    setIsCommandShow(!isCommandShow);
  };
  console.log(
    "connectionLoadingStates--loader deh lo...",
    connectionLoadingStates
  );

  return (
    <>
      {connectionLoadingStates ? (
        <Loader />
      ) : (
        <>
          {gateWayName && (
            <div className="grid flex grid-cols-3 gap-1 pl-4 pr-4 w-[100%] h-screen">
              {/* //main section */}
              <section className="grid grid-rows-[60%_40%] gap-2 grid-cols-1  h-[90%] p-1">
                {/* //inner section 1 */}
                <section className="shadow-inner relative  shadow-gray-500/50 bg-gray-50  p-6 rounded-lg">
                  <div className="border-b-2 flex justify-between">
                    <button
                      className=" text-white w-[100%] bg-blue-300 hover:bg-blue-800 focus:ring-1 focus:ring-blue-300 font-medium rounded-lg text-xs  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-4 py-2 mb-1"
                      // onClick={async () => {
                      //   await serialService.write(`rm:<${panel}>`);
                      // }}
                    >
                      <span>Room List</span>
                    </button>
                    <button
                      className="ml-3 w-[100%] text-white bg-blue-300 hover:bg-blue-800 focus:ring-1 focus:ring-blue-300 font-medium rounded-lg text-xs  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-4 py-2 mb-1"
                      onClick={() => {
                        setAddRoomDialog(true);
                        setRoomName("");
                      }}
                    >
                      Add Room
                    </button>
                  </div>
                  {removeRoomsLoader && (
                    <Loader position="absolute top-[50%]  -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" />
                  )}

                  <div className="flex flex-col p-6">
                    {roomsList?.map((item: any, index: number) => {
                      return (
                        <div key={index} className="flex justify-between pb-2">
                          <>
                            <span className="font-bold">
                              {item?.id}. {item?.nm}
                            </span>
                            <div>
                              <button
                                className="text-white bg-blue-300 hover:bg-blue-800 focus:ring-1 focus:ring-blue-300 font-medium rounded-lg text-xs  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-2 py-2 mr-2"
                                onClick={async () => {
                                  await serialService.write("roomlist");
                                  setSelectedRoomId(item?.id);
                                }}
                              >
                                View
                              </button>
                              <button
                                className="text-white bg-red-300 hover:bg-red-800 focus:ring-1 focus:ring-red-300 font-medium rounded-lg text-xs  dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800 px-2 py-2"
                                onClick={() => {
                                  if (handleRemoveRooms) {
                                    handleRemoveRooms(item);
                                  }
                                }}
                              >
                                Remove
                              </button>
                            </div>
                          </>
                        </div>
                      );
                    })}
                  </div>

                  {/* Room list---- */}
                </section>
                {/* //inner section 2 */}
                <section className="grid shadow-inner shadow-gray-500/50 bg-gray-50 p-6 rounded-lg flex">
                  {/* // ====Scann --- and  data ====*/}
                  <div className=" ">
                    <div className="flex flex-row gap-1 content-end border-b-2">
                      <button
                        type="button"
                        className="ml-3 text-white bg-blue-300 hover:bg-blue-800 focus:ring-1 focus:ring-blue-300 font-medium rounded-lg text-xs  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-4 py-2 mb-1"
                        onClick={async () => {
                          if (isScanning) {
                            handleSearchOff();
                            // serialService.write("searchoff");
                          } else {
                            handleSearchOn();
                            // serialService.write("searchon");
                          }

                          setIsScanning(!isScanning);
                        }}
                      >
                        {isScanning ? "Stop Scanning" : "Start Scanning"}
                      </button>
                      {errorMessage && (
                        <span className="text-red-500 self-center items-center">
                          {errorMessage}
                        </span>
                      )}
                    </div>
                    {parableList.map((panel, index) => {
                      if (panel.trim().length === 0) {
                        return <div />;
                      }
                      return (
                        <div
                          className="flex flex-row gap-6 items-center my-2"
                          key={panel}
                        >
                          <div>{panel}</div>
                          <button
                            className="px-2 py-1 bg-blue-500 text-white font-medium rounded-lg text-xs"
                            onClick={async () => {
                              // handlePairPanel(panel);
                              handlePairDialog(panel);
                              // await serialService.write(`pair:<${panel}>`);
                            }}
                          >
                            Pair
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </section>
              </section>

              <section className="p-1 m-1 rounded-lg shadow-inner shadow-gray-500/50 gap-2 grid grid-cols-1  h-[90%]">
                <div className="p-5 bg-gray-50 rounded-lg">
                  <div className="">
                    {Array.from(new Set(configuredList)).map(
                      (panel: any, index: number) => {
                        if (selectedRoomId !== panel.id) {
                          return;
                        }

                        const dtList = panel.dt ? panel.dt.split(",") : [];

                        return (
                          <>
                            <div
                              key={index}
                              className="flex flex-row gap-1 border-b-2"
                            >
                              <div className="font-semibold text-xl">
                                {panel.nm}
                              </div>
                              <button
                                className="ml-3 text-white bg-blue-300 hover:bg-blue-800 focus:ring-1 focus:ring-blue-300 font-medium rounded-lg text-xs  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-4 py-2 mb-1"
                                onClick={() => {
                                  handlePairPanel(selectedRoomId);
                                }}
                              >
                                Refresh
                              </button>
                            </div>

                            {Array.from(new Set(dtList)).map(
                              (dt: any, indexId: any) => {
                                const panelId = dt.split(":");
                                debugger;
                                return (
                                  <div
                                    className="flex relative justify-between ml-3  mb-2 flex-row gap-6 items-center my-2"
                                    key={indexId}
                                  >
                                    <div>{dt}</div>

                                    <div className="justify-center">
                                      <button
                                        className="text-white bg-red-300 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-xs px-2 py-1 ml-4 mr-4 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800"
                                        onClick={async () => {
                                          handleRemovePanelFromRoom(
                                            panel.id,
                                            panel.nm,
                                            dt
                                          );
                                          // await serialService.write(
                                          //   `rm:<${panel.id}:${panel.nm}:${dt}>`
                                          // );
                                        }}
                                      >
                                        REMOVE
                                      </button>

                                      <button
                                        className="text-white bg-emerald-300 hover:bg-emerald-800 focus:ring-4 focus:ring-emerald-300 font-medium rounded-lg text-xs px-2 py-1  dark:bg-emerald-600 dark:hover:bg-emerald-700 focus:outline-none dark:focus:ring-emerald-800"
                                        onClick={async () => {
                                          handleGetInfoPanel(
                                            panelId[0],
                                            panelId[1]
                                          );
                                        }}
                                      >
                                        GETINFO
                                      </button>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </>
                        );
                      }
                    )}
                  </div>
                </div>
              </section>

              <section className="p-1 gap-2 grid grid-cols-1 grid-rows-[80%_20%] h-[90%]">
                <section className="p-5 shadow-inner shadow-gray-500/50 bg-gray-50 rounded-lg">
                  <div className="font-bold flex pb-4">
                    <div className="font-bold flex items-center ">
                      Show Commands :
                      <div className="pl-4">
                        <ToggleSwitch
                          // buttonId={number}
                          // disabled={loadingStates}
                          // macId={viewPanelButtons.panelId}
                          // sliderValue={sliderValues[number] || 0}
                          isOn={isCommandShow}
                          onToggle={() => handleCommandShow()}
                        />
                      </div>
                    </div>
                    <button
                      className="px-2 py-1 bg-emerald-300 text-black font-medium rounded-lg text-xs ml-10"
                      onClick={() => {
                        setCommands([]);
                      }}
                    >
                      Clear
                    </button>
                  </div>
                  {isCommandShow && (
                    <div className="h-[62vh] overflow-y-auto border rounded-md p-2">
                      <div className="font-bold">Commands:</div>

                      {isCommandShow &&
                        commands.map((command, index) => (
                          <div key={index}>{command}</div>
                        ))}
                    </div>
                  )}
                </section>
                <section className="p-4 shadow-inner shadow-gray-500/50 bg-gray-50 rounded-lg">
                  <div className="border-b-2">
                    <span className="font-bold flex items-center">
                      Connected GateWay
                    </span>
                  </div>
                  <div className="p-3">{gateWayName}</div>
                </section>
              </section>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default MainComponent;
