import React from "react";

interface ModalProps {
  isOpen?: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
}

const CommonModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title = "Modal",
  children,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-2 z-50">
      <div className="bg-white relative p-6 rounded-lg shadow-lg w-96">
        <div className="border-b mb-4 pb-2 flex justify-between items-center">
          <h2 className="text-xl font-semibold">{title}</h2>
          <button className="text-red-500 font-bold text-lg" onClick={onClose}>
            ×
          </button>
        </div>

        <div className="mb-4">{children}</div>
      </div>
    </div>
  );
};

export default CommonModal;
