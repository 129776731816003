import { useContext, useEffect, useRef, useState } from "react";
import { SerialServiceContext } from ".";
import BodyComponent from "./Components/bodyComponent";
import ToggleSwitch from "./Components/toggleSwitch";
import Loader from "./constants/loader";
import ProjectNameDialog from "./Components/projectNameDialog";
import MainComponent from "./Components/MainComponent";
import CommonModal from "./Components/dialogModal/CommonModal";
import vavis_logo from "./assets/images/vavis_logo.png";
import { HiOutlineEye, HiOutlinePencilAlt, HiViewList } from "react-icons/hi";
import { TbCirclesRelation } from "react-icons/tb";
import { buildCommand, RoomCommands } from "./utils/CommandsUtils";
import { CommandService } from "./services/CommandService";
type PanelDetails = {
  panelId: string;
  buttonCommand: string[];
};
type PanelSelectedDetails = {
  panelId: string;
  buttonCommand: string;
  type: string;
};

enum ButtonType {
  DimmerLight = "dimmer_light",
  Light = "light",
  Curtain = "curtain",
}
//multiple pair list.
function App() {
  const context: any = useContext(SerialServiceContext);
  const serialService = useContext(SerialServiceContext);
  const [isConnected, setIsConnected] = useState(false);
  const [commands, setCommands] = useState<string[]>([]);
  const [configuredList, setConfiguredList] = useState<string[]>([]);
  const [parableList, setParableList] = useState<string[]>([]);
  const [roomsList, setRoomsList] = useState<string[]>([]);
  const [bindingPanelList, setBindingPanelList] = useState<string[]>([]);
  const [selectedPanelId, setSelectedPanelId] = useState<string | null>(null);

  const [viewPanelButtons, setViewPanelButtons] =
    useState<PanelDetails | null>();
  const [storeTwoWayPanelButtons, setStoreTwoWayPanelButtons] =
    useState<PanelSelectedDetails | null>();
  const [storeTwoWayPanelData, setStoreTwoWayPanelData] = useState<
    { panelId: string; buttonCommand: number; type: string }[]
  >([]);
  const [sliderValues, setSliderValues] = useState<{ [key: string]: number }>(
    {}
  );
  const [buttonStates, setButtonStates] = useState<{ [key: string]: boolean }>(
    {}
  );

  // const [curtainButtonStates, setCurtainButtonStates] = useState<{
  //   [key: string]: { btn1: boolean; btn2: boolean };
  // }>({});
  const [curtainButtonStates, setCurtainButtonStates] = useState<{
    [key: string]: { btn1: boolean };
  }>({});
  const [loadingStates, setLoadingStates] = useState<boolean>(false);
  const [removeRoomsLoader, setRemoveRoomsLoader] = useState<boolean>(false);
  const [pairwithRoomLoader, setPairwithRoomLoader] = useState<boolean>(false);
  const [connectionLoadingStates, setConnectionLoadingStates] =
    useState<boolean>(false);
  const [loadingRoomListStates, setLoadingRoomListStates] =
    useState<boolean>(false);
  const [deviceloadingStates, setDeviceLoadingStates] =
    useState<boolean>(false);
  const [getInfoMainDialog, setGetInfoMainDialog] = useState<boolean>(false);
  const [editPanelNameDialog, setEditPanelNameDialog] =
    useState<boolean>(false);

  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const [addProjectNameDialog, setAddProjectNameDialog] =
    useState<boolean>(false);
  const [addRoomDialog, setAddRoomDialog] = useState<boolean | undefined>(
    false
  );
  const [roomListDialog, setRoomListDialog] = useState(false);
  const [panelInfo, setPanelInfo] = useState<string | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [openTwoWayBinding, setOpenTwoWayBinding] = useState<boolean>(false);
  const [bindingDialog, setBindingDialog] = useState<boolean>(false);
  const [loaderBindingList, setLoaderBindingList] = useState<boolean>(false);
  const [openTwoWayBindingData, setOpenTwoWayBindingData] =
    useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<string>("-1");
  const [isMacId, setIsMacId] = useState<string>("-1");
  const [projectName, setProjectName] = useState("");
  const [panelName, setPanelName] = useState("");
  const [roomName, setRoomName] = useState<string>("");
  const [gateWayName, setGateWayName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedRoomId, setSelectedRoomId] = useState("-1");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const commandService = new CommandService(serialService);
  const dropdownRef = useRef<HTMLDivElement>(null);
  // const pairResponseTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const sliderValueChanged = (buttonId: string, val: number) => {
    setSliderValues((prev) => ({
      ...prev,
      [buttonId]: val,
    }));
  };

  const parseButtonCommand = (cmd: string) => {
    const parts = cmd.split(",");

    return {
      number: parts[3],
      type: parts[2] as ButtonType,
      relatedbtnnumber: parts[4],
      btnstatus: parts[5]?.trim() === "on",
      relatedbtnstatus: parts[6]?.trim() === "on",
      dimmevalue: parts[7],
      bindingstatus: parts[8],
    };
  };

  // function oncommand(text: string) {
  //   setCommands((command) => [...command, text]);
  // } //--- comment by GS

  useEffect(() => {
    if (viewPanelButtons) {
      viewPanelButtons.buttonCommand.forEach((cmd) => {
        const { number, type, dimmevalue, btnstatus } = parseButtonCommand(cmd);

        if (type === ButtonType.DimmerLight) {
          const dimmerValue = parseInt(dimmevalue, 10);
          setSliderValues((prev) => ({
            ...prev,
            [number]: dimmerValue,
          }));
        }
      });
    }
  }, [viewPanelButtons]);

  useEffect(() => {
    serialService.commandState = oncommand;
  }, [selectedPanelId]);

  useEffect(() => {
    setAddProjectNameDialog(addProjectNameDialog);
  }, [addProjectNameDialog]);

  // useEffect(() => {
  //   return () => {
  //     if (pairResponseTimeoutRef.current) {
  //       clearTimeout(pairResponseTimeoutRef.current);
  //     }
  //   };
  // }, []);

  function oncommand(text: string) {
    try {
      console.log("Received command:", text);

      const cleanedText = text.replace(/\r\n/g, "");

      const parts = cleanedText.split(",");
      const action = parts[1];
      const data: string = parts.slice(2).join(",");
      const colonParts = cleanedText.split(":");
      // if (parts[0] === "cmd" && action === "pair") {
      //   pairResponseTimeoutRef.current = setTimeout(() => {
      //     alert("No response received for cmd<pair within 5 seconds.");
      //   }, 100);
      // }

      // if (parts[0] === "res" && action === "pair") {
      //   if (pairResponseTimeoutRef.current) {
      //     clearTimeout(pairResponseTimeoutRef.current);
      //     pairResponseTimeoutRef.current = null;
      //   }
      // }
      if (parts[0] === "res" && action === "remove") {
        handlePairPanel("");
      }
      //

      // Handle specific actions
      if (colonParts[0].includes("PN_")) {
        setDeviceLoadingStates(false);
      }
      handleSpecialActions(cleanedText, action);
      handleProjectNameSaved(text);
      if (parts[0] === "res") {
        handleConnectionStatus(action, data);
      }
      if (parts[0] === "res") {
        handleBindingAction(action, data);
      }
      if (parts[0] === "res" && action == "pname") {
        handlePairPanel("");
      }
      handleRoomsDataAction(colonParts[0], text);
      if (parts[0] === "res") {
        handleGatewayInfoAction(action, data);
      }
      handleGetInfoAction(action, data);
      handleSendexAction(action, text);

      // Update commands list
      setCommands((prevCommands) => [...prevCommands, text]);
    } catch (error) {
      console.error("Error processing command:", error);
    }
  }

  function handleSpecialActions(cleanedText: string, action: string) {
    if (
      cleanedText === "req<searchon>" ||
      cleanedText.includes("deleteroom") ||
      action === "pair"
    ) {
      setTimeout(() => {
        handlePairPanel("");
      }, 5000);
    }
  }

  function handleProjectNameSaved(text: string) {
    if (text.includes("name saved")) {
      localgetWayInfoFunction();
    }
  }

  function handleConnectionStatus(action: string, data: string) {
    if (action === "getstatus" && data === "true") {
      setTimeout(() => {
        setConnectionLoadingStates(false);
      }, 3000);
      localgetWayInfoFunction();
    }
  }

  function handleBindingAction(action: string, data: string) {
    if (action === "bidetail") {
      setBindingPanelList(data.split(","));
      setLoaderBindingList(false);
    }
  }

  function handleRoomsDataAction(action: string, text: string) {
    if (action === "roomsData") {
      const match = text.match(/<(\[.*\])>/);
      if (match && match[1]) {
        try {
          const roomsArray = JSON.parse(match[1]);
          setConfiguredList(roomsArray);
          setRemoveRoomsLoader(false);

          // Your specific condition for handling rooms data
          const matchRoomData = text.match(/<(\[.*\])>/);
          if (matchRoomData && matchRoomData[1]) {
            const extractedJson = JSON.parse(matchRoomData[1]);
            if (extractedJson && extractedJson?.length > 0) {
              setErrorMessage("");
              setRoomsList(extractedJson);
              setLoadingRoomListStates(false);
              setAddRoomDialog(false);
              setPairwithRoomLoader(false);
            } else {
              setErrorMessage("No Room is available. Please add a room first.");
              setRoomsList([]);
              return;
            }
          }
        } catch (error) {
          console.error("Invalid JSON format:", error);
        }
      } else {
        console.log("No JSON array found in the text.");
      }
    }
  }

  async function handleGatewayInfoAction(action: string, data: string) {
    console.log("hello---");

    if (action === "getwayInfo") {
      await handlePairPanel("");
      const [getWayMacId, projectName] = data.split(",");
      setGateWayName(getWayMacId);
      if (projectName?.trim() === "null") {
        setPanelName("");
        setAddProjectNameDialog(true);
      } else {
        setProjectName(projectName?.trim());
      }
    }
  }

  function handleGetInfoAction(action: string, data: string) {
    if (action === "getinfo") {
      const cleanData = data.replace(/[<>]/g, "").trim();
      const pname = cleanData.split(" - ")[1]?.trim();
      if (pname) setPanelName(pname);

      const buttonCommands = data
        .replace(/<|>/g, "")
        .split(",")
        .filter((cmd) => cmd?.trim().length > 0);

      buttonCommands.forEach((cmd) => {
        const { number, type, btnstatus, dimmevalue } = parseButtonCommand(cmd);
        if (type === ButtonType.DimmerLight) {
          setSliderValues((prev) => ({
            ...prev,
            [number]: parseInt(dimmevalue, 10),
          }));
        }
        setButtonStates((prev) => ({
          ...prev,
          [number]: btnstatus,
        }));
      });

      setTimeout(() => {
        setLoadingStates(false);
      }, 1000);
    }
  }

  function handleSendexAction(action: string, text: string) {
    const match = text.match(/<([^>]+)>/);
    if (!match) {
      console.log("Invalid text format");
      return;
    }

    const data = match[1];
    const parts = data.split(",");

    const type = parts[0];
    const index = parseInt(parts[4], 10);
    const status = parts[5];

    if (parts[1] === selectedPanelId) {
      if (parts[3] === ButtonType.DimmerLight && type !== "sendex") {
        setSliderValues((prev) => ({
          ...prev,
          [parts[2]]: parseInt(parts[5], 10),
        }));
      }
      if (type === "sendex") {
        setButtonStates((prev) => ({
          ...prev,
          [index]: status === "on",
        }));

        setCurtainButtonStates((prev) => ({
          ...prev,
          [index]: {
            btn1: status === "on",
          },
        }));

        setSliderValues((prev) => ({
          ...prev,
          [index]: parseInt(parts[6]),
        }));
      }
    }
    console.log("onClick - LOADER false?");
    // setDeviceLoadingStates(false);
  }
  // end--------

  const isChrome = "chrome" in window;

  if (!isChrome) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen m-10">
        <h1 className="text-3xl">Unsupported Browser</h1>
      </div>
    );
  }
  const handlePairPanel = async (panel: string | null) => {
    await commandService.getRooms();
  };
  const handlePairDialog = async (panel: string) => {
    console.log("seleceted panel -GS", panel);

    setSelectedPanelId(panel);
    setRoomListDialog(true);
  };

  const handleRemoveRooms = async (item: { id: string; nm: string }) => {
    setRemoveRoomsLoader(true);
    await commandService.deleteRoom(item.id, item.nm);
  };
  const handleRemovePanelFromRoom = async (id: string, nm: string, dt: any) => {
    await commandService.removePanelInRoom(id, nm, dt);
  };

  const handleTwoWayBindingRemove = async (item: any) => {
    await commandService.removeBinding(item[0], item[1]);
    setGetInfoMainDialog(false);
    setViewPanelButtons(null);
    setBindingDialog(false);
  };
  const handleSubmitAddRoom = async () => {
    setLoadingRoomListStates(true);
    await serialService.write(`cmd<addroom,${roomName}>`); //nd
    await setTimeout(() => {
      handlePairPanel(selectedPanelId);
    }, 5000);
  };
  const handlePanelWithRoom = async (item: any) => {
    setPairwithRoomLoader(true);
    await commandService.pairRoom(item?.id, item.nm, selectedPanelId);
    setRoomListDialog(false);
  };
  const handleGetTwoWayBindingData = async (btnno: string, macId: string) => {
    setBindingPanelList([]);
    setBindingDialog(true);
    setLoaderBindingList(true);
    await commandService.getBindingDetails(macId, btnno);
  };
  const getConfiguredPanels = async () => {
    setConnectionLoadingStates(true);
    await localFunction();
  };

  const localFunction = async () => {
    await commandService.getPanelStatus();
  };

  const localgetWayInfoFunction = async () => {
    await commandService.getGatewayInfo();
  };

  const handleSearchOn = async () => {
    await commandService.searchOn();
  };

  const handleSearchOff = async () => {
    await commandService.searchOff();
  };

  const handleSubmitProjectName = async () => {
    await commandService.addProjectName(projectName);
    setAddProjectNameDialog(false);
  };
  //---toggle start
  const handleButtonClick = async (
    macId: string,
    number: string,
    sliderValue?: number,
    state?: "on" | "off"
  ) => {
    setLoadingStates(true);
    try {
      setIsMacId(macId);
      let updatedSliderValue = sliderValue;
      if (state === "on") {
        const buttonCommand = viewPanelButtons?.buttonCommand.find((cmd) => {
          const { number: cmdNumber } = parseButtonCommand(cmd);
          return cmdNumber === number;
        });

        if (buttonCommand) {
          const { type } = parseButtonCommand(buttonCommand);
          if (type === ButtonType.DimmerLight) {
            updatedSliderValue = sliderValue == 0 ? 60 : sliderValue;
            setSliderValues((prev: any) => ({
              ...prev,
              [number]: updatedSliderValue,
            }));
          }
        }
      }

      if (state === "off") {
        updatedSliderValue = 0;
        setSliderValues((prev) => ({
          ...prev,
          [number]: 0,
        }));
      }

      setButtonStates((prev) => ({
        ...prev,
        [number]: state === "on",
      }));
      await serialService.write(
        `sendrf,ucast,${macId},<req,${state},${number},${updatedSliderValue},null>\r\n`
      );
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingStates(false);
    }
  };
  //--end toggle button
  const handleGetInfoTwoWayBinding = async (panel: string) => {
    setViewPanelButtons(null);
    setOpenTwoWayBindingData(true);
    await commandService.panelInfo(panel);
  };

  const handleEditPanelName = async () => {
    debugger;
    setErrorMessage("");
    let newPanelName: string = "";

    if (panelName === "") {
      return setErrorMessage("This field cannot be empty");
    } else {
      newPanelName = `:${panelName}`;
    }
    await commandService.renamePanel(viewPanelButtons?.panelId, newPanelName);

    // await getConfiguredPanels();
    setEditPanelNameDialog(false);
  };

  const handleCloseTwoWayBinding = () => {
    setSelectedValues([]);
    setOpenTwoWayBinding(false);
    // setSelectedPanelId(null);
  };

  const handleDropdownMenu = (dropdownData: boolean, index: string) => {
    setIsDropdownOpen(dropdownData);
    setSelectedIndex(index);
  };
  const handleTwoWayBindingDataClose = () => {
    //----last
    setOpenTwoWayBindingData(false);
    setOpenTwoWayBinding(false);
    setViewPanelButtons(null);
    setGetInfoMainDialog(false);
    setSelectedValues([]);
    // setSelectedPanelId(null);
  };
  const handleTwoWayBinding = (id: string, index: string, type: string) => {
    // setStoreTwoWayPanelData([]);
    setOpenTwoWayBindingData(false);
    setOpenTwoWayBinding(true);
    setIsMacId(id);
    setSelectedValues((prevValues) => [...prevValues, id]);
    setStoreTwoWayPanelData((pre: any) => {
      let obj = {
        panelId: id,
        buttonCommand: index,
        type: type,
      };
      return [...pre, obj];
    });
    // setViewPanelButtons(null);

    setStoreTwoWayPanelButtons(() => {
      let obj = {
        panelId: id,
        buttonCommand: index,
        type: type,
      };

      return obj;
    });
    setIsDropdownOpen(false);
  };
  const handleWithTwoWayBinding = async (panelId: string, number: string) => {
    console.log("new log data will come with dance..", storeTwoWayPanelData);
    // debugger;
    const uniqueBindings = Array.from(
      new Map(storeTwoWayPanelData.map((item) => [item.panelId, item])).values()
    );

    const dynamicBindings = uniqueBindings
      .map((item) => `${item.panelId}:${item.buttonCommand}`)
      .join(",");

    const prePanelId = storeTwoWayPanelButtons?.panelId || "defaultPrePanelId";
    const preButtonNumber =
      storeTwoWayPanelButtons?.buttonCommand || "defaultPreButtonNumber";

    setSelectedValues((prevValues) => [...prevValues, panelId]);
    await commandService.setTwoWayBinding(panelId, number, dynamicBindings); //new added

    setOpenTwoWayBindingData(false);
    setOpenTwoWayBinding(false);
    setGetInfoMainDialog(false);
    const alertDynamicBindings = uniqueBindings
      .map((item) => `${item.panelId}:${item.buttonCommand}`)
      .join("to");
    alert(
      `Success! Binding with  ${panelId}:${number} to ${alertDynamicBindings}.`
    );
  };
  const handleClosePanelInformationModel = () => {
    setIsInfoModalOpen(false);
    setSelectedPanelId(null);
  };
  const handleClosePanelButtonModel = () => {
    setViewPanelButtons(null);
    setGetInfoMainDialog(false);
    setSelectedPanelId(null);
    setButtonStates({});
    setCurtainButtonStates({});
  };
  const handleCloseProjectNameDialog = () => {
    setAddProjectNameDialog(false);
  };
  const handleGetInfoPanel = async (panelId: string, panelName: string) => {
    // debugger;
    // setIsDropdownOpen()
    setSelectedValues([]);
    setPanelName("");
    setStoreTwoWayPanelData([]);
    setSelectedPanelId(panelId);
    setIsDropdownOpen(false);
    await setGetInfoMainDialog(true);
    await setDeviceLoadingStates(true);
    {
      panelName && setPanelName(panelName);
    }
    await commandService.panelInfo(panelId);
  };

  const toggleButtons1 = (
    panelId: string,
    buttonNumber: string,
    relatedButtonNumber: string
  ) => {
    setCurtainButtonStates((prevState) => {
      const currentState = prevState[buttonNumber]?.btn1 ?? false;
      return {
        ...prevState,
        [buttonNumber]: { btn1: !currentState },
        [relatedButtonNumber]: { btn1: false },
      };
    });

    setTimeout(() => {
      setCurtainButtonStates((prevState) => {
        handleButtonClick(
          panelId,
          buttonNumber,
          0,
          prevState[buttonNumber]?.btn1 ? "on" : "off"
        );
        handleButtonClick(
          panelId,
          relatedButtonNumber,
          0,
          prevState[relatedButtonNumber]?.btn1 ? "on" : "off"
        );
        return prevState;
      });
    }, 0);
  };

  console.log("oooo-configuredList", viewPanelButtons);

  return (
    <div className="flex relative  flex-col items-center justify-center min-h-screen m-0">
      {addProjectNameDialog && (
        <ProjectNameDialog
          setProjectName={setProjectName}
          projectName={projectName}
          handleCloseProjectNameDialog={handleCloseProjectNameDialog}
          handleSubmitProjectName={handleSubmitProjectName}
          showCloseButton={false}
        />
      )}

      {!openTwoWayBinding && getInfoMainDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-2 z-50">
          <div className="bg-white relative p-6 rounded-lg shadow-lg w-96 min-h-[70%]">
            {isDropdownOpen && (
              <button
                className=" flex h-full z-40 w-full absolute"
                onClick={() => setIsDropdownOpen(false)}
              />
            )}

            {isInfoModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative">
                  <h2 className="text-xl font-semibold mb-4">
                    Panel Information
                  </h2>
                  <div className="text-gray-800 whitespace-pre-wrap">
                    {panelInfo || "Loading..."}
                  </div>
                  <button
                    className="absolute top-2 right-2 text-red-500 font-bold text-lg"
                    onClick={() => handleClosePanelInformationModel()}
                  >
                    ×
                  </button>
                </div>
              </div>
            )}
            {deviceloadingStates ? (
              <Loader position="absolute top-[50%]  -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" />
            ) : (
              viewPanelButtons && (
                <div>
                  <div className="border-b mb-3">
                    <div className="mb-0 flex ">
                      <strong className="block text-gray-700 mb-0">ID:</strong>
                      <div className="text-gray-800">
                        {viewPanelButtons.panelId}
                      </div>
                    </div>
                    <div className="mb-0 flex  mb-4">
                      <strong className="block text-gray-700 mb-0">
                        Name:
                      </strong>

                      <div className="text-gray-800">
                        {panelName ? panelName : viewPanelButtons.panelId}
                      </div>
                      <button
                        type="button"
                        onClick={() => {
                          setEditPanelNameDialog(true);
                          // setPanelName("");
                          // handleEditPanelName()
                        }}
                        className="pl-2"
                      >
                        <HiOutlinePencilAlt height={50} width={10} size={20} />
                      </button>
                    </div>
                  </div>
                  <div className="block relative overflow-y-auto  max-h-[60vh] ">
                    <ul className="space-y-2">
                      {viewPanelButtons?.buttonCommand.map((cmd, index) => {
                        const {
                          number,
                          type,
                          btnstatus,
                          relatedbtnnumber,
                          relatedbtnstatus,
                          bindingstatus,
                        }: any = parseButtonCommand(cmd);

                        if (!Object.values(ButtonType).includes(type)) {
                          return null;
                        }

                        return type === ButtonType.Curtain ? (
                          <div key={number} className=" border-b pb-2">
                            <li className="flex flex-col gap-2">
                              <div className="flex justify-between items-center">
                                <span className="text-gray-600 font-medium">
                                  Button {number} {relatedbtnnumber}
                                </span>
                                <div className="flex items-center gap-2">
                                  {/* //=====For twoway binding list======= */}

                                  <button
                                    type="button"
                                    className={`ml-3 text-white px-2 py-2 mb-1 text-[10px] font-medium rounded-lg focus:outline-none focus:ring-1 
                                      ${
                                        curtainButtonStates[number]?.btn1
                                          ? "bg-green-300 hover:bg-green-800 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                                          : "bg-gray-400 hover:bg-gray-600 focus:ring-gray-300 dark:bg-gray-400 dark:hover:bg-gray-500 dark:focus:ring-gray-600"
                                      }`}
                                    onClick={() =>
                                      toggleButtons1(
                                        viewPanelButtons.panelId,
                                        number,
                                        relatedbtnnumber
                                      )
                                    }
                                  >
                                    {"OPEN"}
                                  </button>
                                  <button
                                    type="button"
                                    className={`ml-3 text-white px-2 py-2 mb-1 text-[10px] font-medium rounded-lg focus:outline-none focus:ring-1 
                                      ${
                                        curtainButtonStates[relatedbtnnumber]
                                          ?.btn1
                                          ? "bg-green-300 hover:bg-green-800 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                                          : "bg-gray-400 hover:bg-gray-600 focus:ring-gray-300 dark:bg-gray-400 dark:hover:bg-gray-500 dark:focus:ring-gray-600"
                                      }`}
                                    onClick={() =>
                                      toggleButtons1(
                                        viewPanelButtons.panelId,
                                        relatedbtnnumber,
                                        number
                                      )
                                    }
                                  >
                                    {"CLOSE"}
                                  </button>
                                  {/* //=====Now for this is not wrKing===== */}
                                  <button className=" pl-6 pr-6"></button>
                                  {selectedIndex === number &&
                                    isDropdownOpen && (
                                      <div
                                        ref={dropdownRef}
                                        className="absolute right-4 flex z-50 bg-white divide-y divide-gray-100 rounded-lg shadow w-22"
                                      >
                                        <ul className=" text-sm text-gray-700">
                                          <li className="block px-4 py-2 hover:bg-gray-100">
                                            <button
                                              onClick={() =>
                                                handleTwoWayBinding(
                                                  viewPanelButtons.panelId,
                                                  number,
                                                  type
                                                )
                                              }
                                            >
                                              Two way binding
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </li>
                          </div>
                        ) : (
                          <div key={number} className=" border-b pb-2">
                            <li className="flex flex-col gap-2">
                              <div className="flex justify-between items-center">
                                <span className="text-gray-600 font-medium">
                                  Button {number}
                                </span>
                                <div className="flex items-center gap-2">
                                  {bindingstatus === "yes" && (
                                    <button
                                      className=" pl-6 pr-6"
                                      onClick={() =>
                                        handleGetTwoWayBindingData(
                                          number,
                                          viewPanelButtons.panelId
                                        )
                                      }
                                    >
                                      <TbCirclesRelation
                                        height={50}
                                        width={10}
                                        size={20}
                                      />
                                    </button>
                                  )}

                                  <ToggleSwitch
                                    buttonId={number}
                                    disabled={loadingStates}
                                    macId={viewPanelButtons.panelId}
                                    sliderValue={sliderValues[number] || 0}
                                    isOn={buttonStates[number] ?? btnstatus} // comment by gs
                                    // isOn={btnstatus}
                                    onToggle={(newState: any) =>
                                      handleButtonClick(
                                        viewPanelButtons.panelId,
                                        number,
                                        sliderValues[number],
                                        newState ? "on" : "off"
                                      )
                                    }
                                  />

                                  <button
                                    className=" pl-6 pr-6"
                                    onClick={() =>
                                      handleDropdownMenu(
                                        !isDropdownOpen,
                                        number
                                      )
                                    }
                                  >
                                    <ul className="-space-y-8">
                                      <li>•</li>
                                      <li>•</li>
                                      <li>•</li>
                                    </ul>
                                  </button>
                                  {selectedIndex === number &&
                                    isDropdownOpen && (
                                      <div
                                        ref={dropdownRef}
                                        className="absolute right-4 flex z-50 bg-white divide-y divide-gray-100 rounded-lg shadow w-22"
                                      >
                                        <ul className=" text-sm text-gray-700">
                                          <li className="block px-4 py-2 hover:bg-gray-100">
                                            <button
                                              onClick={() =>
                                                handleTwoWayBinding(
                                                  viewPanelButtons.panelId,
                                                  number,
                                                  type
                                                )
                                              }
                                            >
                                              Two way binding
                                            </button>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                </div>
                              </div>
                              {type === "dimmer_light" && (
                                <RangeSlider
                                  buttonId={number}
                                  macId={viewPanelButtons.panelId}
                                  value={sliderValues[number] || 0}
                                  onChange={(value: number) =>
                                    sliderValueChanged(number, value)
                                  }
                                  handleButtonClick={handleButtonClick}
                                  classes="w-full"
                                />
                              )}
                            </li>
                          </div>
                        );
                      })}
                    </ul>
                    {loadingStates && <Loader />}
                  </div>
                </div>
              )
            )}
            <button
              className="absolute top-1 right-2 text-red-500 font-bold text-lg"
              onClick={() => handleClosePanelButtonModel()}
            >
              ×
            </button>
          </div>
        </div>
      )}
      {/* //dailog - model */}
      {editPanelNameDialog && (
        <ProjectNameDialog
          setProjectName={setPanelName}
          projectName={panelName}
          handleCloseProjectNameDialog={() => setEditPanelNameDialog(false)}
          handleSubmitProjectName={handleEditPanelName}
          title="Add Panel Name"
          errorMessage={errorMessage}
        />
      )}
      {addRoomDialog && (
        <ProjectNameDialog
          setProjectName={setRoomName}
          projectName={roomName}
          handleCloseProjectNameDialog={() => setAddRoomDialog(false)}
          handleSubmitProjectName={handleSubmitAddRoom}
          title="Add Room"
          loading={loadingRoomListStates}
        />
      )}

      {roomListDialog && roomsList && (
        <CommonModal
          title="Rooms list"
          isOpen={roomListDialog}
          onClose={() => setRoomListDialog(false)}
        >
          <div>
            {roomsList?.map((item: any, index) => {
              return (
                <div key={index} className="flex justify-between pb-4">
                  {loadingRoomListStates ? (
                    <Loader position="absolute top-[50%]  -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" />
                  ) : (
                    <>
                      <span className="font-bold">
                        {item?.id} {item?.nm}
                      </span>
                      <button
                        className="text-white bg-blue-300 hover:bg-blue-800 focus:ring-1 focus:ring-blue-300 font-medium rounded-lg text-xs  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-2 py-2"
                        onClick={() => handlePanelWithRoom(item)}
                      >
                        Add
                      </button>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </CommonModal>
      )}
      {pairwithRoomLoader && <Loader />}
      {bindingDialog && bindingPanelList && (
        <CommonModal
          title="Two way binding list"
          isOpen={bindingDialog}
          onClose={() => setBindingDialog(false)}
        >
          <div>
            {loaderBindingList && (
              <Loader position="absolute top-[80%]  -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" />
            )}
            {bindingPanelList?.map((item: any, index) => {
              const newData = item.split(":");
              return (
                <div key={index} className="flex justify-between pb-4">
                  {/* {loadingRoomListStates ? (
                    <Loader position="absolute top-[50%]  -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" />
                  ) : ( */}
                  <>
                    <span className="font-bold">
                      {newData[0]} -{newData[1]}
                    </span>
                    <button
                      className="text-white bg-blue-300 hover:bg-blue-800 focus:ring-1 focus:ring-blue-300 font-medium rounded-lg text-xs  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 px-2 py-2"
                      onClick={async () => handleTwoWayBindingRemove(newData)}
                    >
                      Remove
                    </button>
                  </>
                  {/* )} */}
                </div>
              );
            })}
          </div>
        </CommonModal>
      )}

      {openTwoWayBinding && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-2 z-50">
          <div className="bg-white relative p-6 rounded-lg shadow-lg w-96 ">
            <div className="border-b mt-10">
              <h2 className="text-xl font-semibold mb-2  pb-2">
                For two way find Panels
              </h2>
            </div>

            {Array.from(new Set(configuredList)).map((panel: any) => {
              const dtList = panel.dt ? panel.dt.split(",") : [];
              console.log("bade miya chote miya", selectedValues);
              return (
                <>
                  {Array.from(new Set(dtList))
                    .filter((dt: any) => {
                      const dtValue = dt.includes("-") ? dt.split("-")[0] : dt;
                      return !selectedValues.some((id) => id.includes(dtValue));
                    })
                    .map((dt: any, indexT: any) => {
                      const dtValue = dt.includes("-") ? dt.split("-")[0] : dt;
                      return (
                        <div
                          className="flex flex-row gap-6 items-center my-2"
                          key={indexT}
                        >
                          <div>{dt}</div>
                          <button
                            className="px-2 py-1 bg-emerald-300 text-black font-medium rounded-lg text-xs"
                            onClick={() => handleGetInfoTwoWayBinding(dtValue)}
                          >
                            GETINFO
                          </button>
                        </div>
                      );
                    })}
                </>
              );
            })}
            {/* //data---- */}
            <button
              className="absolute top-1 right-2 text-red-500 font-bold text-lg"
              onClick={() => handleCloseTwoWayBinding()} //setOpenTwoWayBinding(false)
            >
              ×
            </button>
          </div>
        </div>
      )}
      {openTwoWayBindingData && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-2 z-50">
          <div className="bg-white relative p-6 rounded-lg shadow-lg w-96 min-h-[70%] ">
            {deviceloadingStates ? (
              <Loader position="absolute top-[50%]  -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" />
            ) : viewPanelButtons ? (
              <div>
                <div className="border-b mt-10">
                  <h2 className="text-xl font-semibold mb-2  pb-2">
                    Panel Buttons
                  </h2>
                </div>
                {viewPanelButtons?.panelId && (
                  <div className="mb-0">
                    <strong className="block text-gray-700 mb-0">
                      Panel ID:
                    </strong>
                    <div className="text-gray-800">
                      {viewPanelButtons?.panelId}
                    </div>
                  </div>
                )}
                <div className="mt-3">
                  {viewPanelButtons?.buttonCommand.map((cmd, index) => {
                    const { number, type, btnstatus } = parseButtonCommand(cmd);
                    if (!Object.values(ButtonType).includes(type)) {
                      return null;
                    }

                    const extractedDtData = configuredList
                      .map((item: any) => item.dt) // Extract dt values
                      .filter((dt) => dt) // Remove undefined or empty values
                      .flatMap((dt) => dt.split(","));

                    const filteredDtData = extractedDtData.filter(
                      (dt) => !selectedValues.includes(dt)
                    );
                    console.log("poppselectedValues", filteredDtData);

                    return (
                      <>
                        {storeTwoWayPanelButtons?.type ===
                          ButtonType.DimmerLight &&
                          type === ButtonType.DimmerLight && (
                            <div className="block relative overflow-y-auto  max-h-[400px]">
                              <ul className="space-y-2">
                                <div key={number} className=" border-b pb-2">
                                  <li className="mt-5 flex flex-col gap-2">
                                    <div className="flex justify-between items-center">
                                      <span className="text-gray-600 font-medium">
                                        Button {number}
                                      </span>
                                      <div className="flex items-center gap-2">
                                        <button
                                          className=" pl-6 pr-6"
                                          onClick={() =>
                                            handleDropdownMenu(
                                              !isDropdownOpen,
                                              number
                                            )
                                          }
                                        >
                                          <ul className="-space-y-8">
                                            <li>•</li>
                                            <li>•</li>
                                            <li>•</li>
                                          </ul>
                                        </button>
                                        {selectedIndex === number &&
                                          isDropdownOpen && (
                                            <>
                                              <div
                                                ref={dropdownRef}
                                                className="absolute border border-blue-500 flex-row right-4 flex z-50 bg-white divide-y divide-gray-100 rounded-lg shadow w-22"
                                              >
                                                {filteredDtData.length > 1 && (
                                                  <ul className="border-r border-blue-500  text-sm text-gray-700">
                                                    <li className="block px-4 py-2 rounded-lg hover:bg-gray-100">
                                                      <button
                                                        onClick={() =>
                                                          handleTwoWayBinding(
                                                            viewPanelButtons.panelId,
                                                            number,
                                                            type
                                                          )
                                                        }
                                                      >
                                                        With two way binding
                                                      </button>
                                                      {/* )} */}
                                                    </li>
                                                  </ul>
                                                )}
                                                <ul className=" text-sm text-gray-700">
                                                  <li className="block px-4 py-2 rounded-lg hover:bg-gray-100">
                                                    <button
                                                      onClick={() =>
                                                        handleWithTwoWayBinding(
                                                          viewPanelButtons.panelId,
                                                          number
                                                        )
                                                      }
                                                    >
                                                      Submit
                                                    </button>
                                                  </li>
                                                </ul>
                                                {loadingStates && <Loader />}
                                              </div>
                                            </>
                                          )}
                                      </div>
                                    </div>
                                    {type === "dimmer_light" && (
                                      <RangeSlider
                                        buttonId={number}
                                        macId={viewPanelButtons.panelId}
                                        value={sliderValues[number] || 0}
                                        onChange={(value: number) =>
                                          sliderValueChanged(number, value)
                                        }
                                        handleButtonClick={handleButtonClick}
                                        classes="w-full"
                                      />
                                    )}
                                  </li>
                                </div>
                              </ul>
                            </div>
                          )}
                        {storeTwoWayPanelButtons?.type === ButtonType.Light &&
                          type === ButtonType.Light && (
                            <div className="block relative overflow-y-auto  max-h-[400px]">
                              <ul className="space-y-2">
                                <div key={number} className=" border-b pb-2">
                                  <li className="mt-3 flex flex-col gap-2">
                                    <div className="flex justify-between items-center">
                                      <span className="text-gray-600 font-medium">
                                        Button {number}
                                      </span>
                                      <div className="flex items-center gap-2">
                                        <button
                                          className=" pl-6 pr-6"
                                          onClick={() =>
                                            handleDropdownMenu(
                                              !isDropdownOpen,
                                              number
                                            )
                                          }
                                        >
                                          <ul className="-space-y-8">
                                            <li>•</li>
                                            <li>•</li>
                                            <li>•</li>
                                          </ul>
                                        </button>
                                        {selectedIndex === number &&
                                          isDropdownOpen && (
                                            <>
                                              <div
                                                ref={dropdownRef}
                                                className="absolute border border-blue-500 flex-row right-4 flex z-50 bg-white divide-y divide-gray-100 rounded-lg shadow w-22"
                                              >
                                                {filteredDtData.length > 1 && (
                                                  <ul className="border-r border-blue-500  text-sm text-gray-700">
                                                    <li className="block px-4 py-2 rounded-lg hover:bg-gray-100">
                                                      <button
                                                        onClick={() =>
                                                          handleTwoWayBinding(
                                                            viewPanelButtons.panelId,
                                                            number,
                                                            type
                                                          )
                                                        }
                                                      >
                                                        With two way binding
                                                      </button>
                                                    </li>
                                                  </ul>
                                                )}
                                                <ul className=" text-sm text-gray-700">
                                                  <li className="block px-4 py-2 rounded-lg hover:bg-gray-100">
                                                    <button
                                                      onClick={() =>
                                                        handleWithTwoWayBinding(
                                                          viewPanelButtons.panelId,
                                                          number
                                                        )
                                                      }
                                                    >
                                                      Submit
                                                    </button>
                                                  </li>
                                                </ul>
                                                {loadingStates && <Loader />}
                                              </div>
                                            </>
                                          )}
                                      </div>
                                    </div>
                                  </li>
                                </div>
                              </ul>
                            </div>
                          )}
                        {storeTwoWayPanelButtons?.type === ButtonType.Curtain &&
                          type === ButtonType.Curtain && (
                            <div className="block relative overflow-y-auto  max-h-[400px]">
                              <ul className="space-y-2">
                                <div key={number} className=" border-b pb-2">
                                  <li className="mt-3 flex flex-col gap-2">
                                    <div className="flex justify-between items-center">
                                      <span className="text-gray-600 font-medium">
                                        Button {number}
                                      </span>
                                      <div className="flex items-center gap-2">
                                        <button
                                          className=" pl-6 pr-6"
                                          onClick={() =>
                                            handleDropdownMenu(
                                              !isDropdownOpen,
                                              number
                                            )
                                          }
                                        >
                                          <ul className="-space-y-8">
                                            <li>•</li>
                                            <li>•</li>
                                            <li>•</li>
                                          </ul>
                                        </button>
                                        {selectedIndex === number &&
                                          isDropdownOpen && (
                                            <>
                                              <div
                                                ref={dropdownRef}
                                                className="absolute border border-blue-500 flex-row right-4 flex z-50 bg-white divide-y divide-gray-100 rounded-lg shadow w-22"
                                              >
                                                {filteredDtData.length > 1 && (
                                                  <ul className="border-r border-blue-500  text-sm text-gray-700">
                                                    <li className="block px-4 py-2 rounded-lg hover:bg-gray-100">
                                                      <button
                                                        onClick={() =>
                                                          handleTwoWayBinding(
                                                            viewPanelButtons.panelId,
                                                            number,
                                                            type
                                                          )
                                                        }
                                                      >
                                                        With two way binding
                                                      </button>
                                                    </li>
                                                  </ul>
                                                )}
                                                <ul className=" text-sm text-gray-700">
                                                  <li className="block px-4 py-2 rounded-lg hover:bg-gray-100">
                                                    <button
                                                      onClick={() =>
                                                        handleWithTwoWayBinding(
                                                          viewPanelButtons.panelId,
                                                          number
                                                        )
                                                      }
                                                    >
                                                      Submit
                                                    </button>
                                                  </li>
                                                </ul>
                                                {loadingStates && <Loader />}
                                              </div>
                                            </>
                                          )}
                                      </div>
                                    </div>
                                  </li>
                                </div>
                              </ul>
                            </div>
                          )}
                      </>
                    );
                  })}
                </div>
              </div>
            ) : (
              <Loader position="absolute top-[50%]  -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2" />
            )}
            <button
              className="absolute top-1 right-2 text-red-500 font-bold text-lg"
              onClick={() => handleTwoWayBindingDataClose()}
            >
              ×
            </button>
          </div>
        </div>
      )}
      <div className="flex justify-start w-[100%] mt-6 mb-4">
        <div className="flex ml-3">
          {!connectionLoadingStates && gateWayName && (
            <>
              <img src={vavis_logo} height={130} width={130} alt="Logo" />
              <div className="flex flex-col">
                <div className="ml-4 font-bold text-gray-500 text-sm">
                  {projectName}
                </div>
                <div className="ml-4 font-bold text-gray-500 text-sm">
                  {gateWayName}
                </div>
              </div>
            </>
          )}
        </div>

        <div className="ml-[25%]">
          {!connectionLoadingStates && (
            <div className="flex flex-row gap-4">
              <h1 className="text-3xl">
                Start Config [{isConnected ? "Connected" : "Not Connected"}]
              </h1>
              <button
                className="px-5 py-2 bg-slate-400 text-white font-bold rounded-lg"
                onClick={async () => {
                  if (isConnected === true) {
                    await serialService.disconnect();
                    setIsConnected(false);
                    setConnectionLoadingStates(false);
                  } else {
                    serialService.connect(
                      (status: boolean | ((prevState: boolean) => boolean)) => {
                        setIsConnected(status);
                        setConfiguredList([]);
                        setParableList([]);
                        getConfiguredPanels();
                      },
                      (text) => {
                        setLoadingStates(true);
                        const [action, data, pname] = text.split(":");
                        const cleanedText = text.replace(/\r\n/g, "");
                        // const [action, data] = text.split(":");
                        const parts = cleanedText.split(",");
                        const newAction = parts[1];
                        const newData = parts.slice(2).join(",");
                        const rowAction = action?.trim();
                        const rowData = data
                          ?.replaceAll("<", "")
                          ?.replaceAll(">", "");
                        if (rowAction.includes("PN_")) {
                          const [_, macAddress] = rowAction.split("PN_");
                          //GS-my chacK data

                          setViewPanelButtons((obj) => {
                            if (obj == null) {
                              obj = {
                                panelId: macAddress,
                                buttonCommand: [rowData],
                              };
                            } else if (obj.panelId == macAddress) {
                              if (!obj.buttonCommand.includes(rowData)) {
                                obj.buttonCommand.push(rowData);
                              }
                              // obj.buttonCommand.push(rowData);
                            } else {
                              obj = {
                                panelId: macAddress,
                                buttonCommand: [rowData],
                              };
                            }
                            console.log("offf Kya rat ai hai..", obj);

                            return obj;
                          });

                          const parsedCommand = parseButtonCommand(rowData);
                          if (parsedCommand.type === ButtonType.Curtain) {
                            setCurtainButtonStates((prevState) => ({
                              ...prevState,
                              [parsedCommand.number]: {
                                btn1: parsedCommand.btnstatus,
                              },
                              [parsedCommand.relatedbtnnumber]: {
                                btn1: parsedCommand.relatedbtnstatus,
                              },
                            }));
                          }
                        }

                        if (newAction === "searchon") {
                          if (newData?.trim().length === 0) {
                            setParableList([]);
                            return;
                          }
                          const list = newData
                            .split(",")
                            .map((panel: string) => panel?.trim());
                          setParableList(list);
                        } else if (rowAction === "PR") {
                          if (rowData?.trim().length === 0) {
                            setConfiguredList([]);
                            return;
                          }

                          const list = rowData
                            .split(",")
                            .map((panel: string) => panel?.trim()); // comment by GS

                          setConfiguredList(list);
                        } else if (rowAction === "PROK") {
                          getConfiguredPanels();
                        } else if (rowAction === "RMOK") {
                          getConfiguredPanels();
                        }
                        setLoadingStates(false);
                      }
                    );
                  }
                }}
              >
                {isConnected ? "Disconnect" : "Connect"}
              </button>
            </div>
          )}
        </div>
      </div>
      {!addProjectNameDialog && (
        <MainComponent
          configuredList={configuredList}
          getConfiguredPanels={getConfiguredPanels}
          parableList={parableList}
          isConnected={isConnected}
          commands={commands}
          setCommands={setCommands}
          handleGetInfoPanel={handleGetInfoPanel}
          serialService={serialService}
          connectionLoadingStates={connectionLoadingStates}
          gateWayName={gateWayName}
          handlePairPanel={handlePairPanel}
          setAddRoomDialog={setAddRoomDialog}
          errorMessage={errorMessage}
          roomsList={roomsList}
          handleRemoveRooms={handleRemoveRooms}
          handlePairDialog={handlePairDialog}
          setSelectedRoomId={setSelectedRoomId}
          selectedRoomId={selectedRoomId}
          removeRoomsLoader={removeRoomsLoader}
          setRoomName={setRoomName}
          handleSearchOn={handleSearchOn}
          handleSearchOff={handleSearchOff}
          handleRemovePanelFromRoom={handleRemovePanelFromRoom}
        />
      )}

      {/* <BodyComponent
        configuredList={configuredList}
        getConfiguredPanels={getConfiguredPanels}
        parableList={parableList}
        isConnected={isConnected}
        commands={commands}
        setCommands={setCommands}
        handleGetInfoPanel={handleGetInfoPanel}
        serialService={serialService}
        connectionLoadingStates={connectionLoadingStates}
      /> */}
    </div>
  );
}

const RangeSlider = ({
  classes,
  label,
  onChange,
  value,
  buttonId,
  macId,
  handleButtonClick,
  ...sliderProps
}: any) => {
  const [sliderVal, setSliderVal] = useState(0);

  useEffect(() => {
    setSliderVal(value);
  }, [value]);

  const changeCallback = (e: any) => {
    const newValue = parseInt(e.target.value, 10);
    setSliderVal(newValue);
  };

  const handleMouseUp = () => {
    const state = sliderVal === 0 ? "off" : "on";
    onChange(sliderVal);
    handleButtonClick(macId, buttonId, sliderVal, state);
  };

  const ticks = Array.from({ length: 6 }, (_, index) => index * 20);

  return (
    <div className="range-slider">
      <p>{label}</p>
      <h4>Value: {sliderVal}</h4>
      <input
        type="range"
        value={sliderVal}
        step={20}
        min={0}
        max={100}
        {...sliderProps}
        className={`slider ${classes}`}
        id="myRange"
        onChange={changeCallback}
        onMouseUp={handleMouseUp}
      />
      <div className="flex justify-between">
        {ticks.map((tick) => (
          <span key={tick} className="text-xs">
            {tick}
          </span>
        ))}
      </div>
    </div>
  );
};

export default App;
