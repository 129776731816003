import { Navigator, SerialPort } from "./interface";

export interface IVavisSerialConnection {
  allCommands: string;
  commandState?: (text: string) => void;
  connect(
    isConnected: (status: boolean) => void,
    onResponse: (text: string) => void
  ): Promise<void>;
  disconnect(): Promise<void>;
  write(text: string): Promise<void>;
  read(): Promise<string>;
}

export class VavisSerialConnection implements IVavisSerialConnection {
  port: SerialPort | null = null;
  timeOut: number = 60000;
  timeoutController?: NodeJS.Timeout;
  deviceResponses = "";
  isTimedOut = false;
  isContinuousReading = false;
  reader?: any;
  writer?: any;
  allCommands: string = "";
  commandState?: (text: string) => void;

  onCommands(text: string) {
    this.allCommands += text;
    if (this.commandState) {
      this.commandState(text);
    }
  }

  async connect(
    isConnected: (status: boolean) => void,
    onResponse: (text: string) => void
  ): Promise<void> {
    await this.disconnect();
    let devicePort: SerialPort | null | undefined;
    const nav = navigator as unknown as Navigator;
    const port = await nav.serial.getPorts();

    if (!port.length) {
      try {
        devicePort = await nav.serial.requestPort();
      } catch (error) {}
    } else {
      devicePort = port[0];
    }

    if (!devicePort) {
      isConnected(false);
      return;
    }

    this.port = devicePort;

    // Start Port
    try {
      this.port && (await this.port.open({ baudRate: 115200 }));

      await new Promise((resolve) => setTimeout(resolve, 100));

      isConnected(true);

      this.isContinuousReading = true;

      while (this.isContinuousReading) {
        const value = await this.read();

        this.deviceResponses += value;
        const isLastMsg = this.isCompleteMsg(value);
        if (isLastMsg) {
          const commands = this.deviceResponses.split("\n");

          if (commands.length == 0) {
            onResponse(this.deviceResponses);
          } else {
            commands.forEach((element) => {
              onResponse(element);
            });
          }

          this.onCommands(this.deviceResponses);
          this.deviceResponses = "";
        }
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      isConnected(false);
    }
  }

  async disconnect() {
    this.isContinuousReading = false;
    if (this.port) {
      try {
        if (this.reader) {
          await this.reader.cancel();
          await this.reader.releaseLock();
        }

        if (this.writer) {
          await this.writer.releaseLock();
        }

        await this.port.close();
        this.port = null;

        console.log("Port disconnected");
      } catch (error) {
        console.error("Error disconnecting the port:", error);
      }
    } else {
      console.log("No port to disconnect");
    }
  }

  async write(text: string) {
    console.log("write:", text);
    try {
      this.onCommands(text);
      if (this.port && this.port.writable) {
        this.writer = this.port.writable?.getWriter();
        const data = this.stringToUintArray(text);
        const uintArr = new Uint8Array(data);
        await this.writer.write(uintArr);
        this.writer.releaseLock();
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async read(): Promise<string> {
    try {
      if (this.port && this.port.readable) {
        try {
          this.reader = this.port.readable.getReader();
          const { value } = await this.reader.read();

          this.reader.releaseLock();

          const str = this.uintArrayToString(value);
          return str;
        } catch (error) {
          const reader = this.port.readable.getReader();
          reader.releaseLock();
        }
      }
    } catch (error) {}
    return "";
  }

  stringToUintArray(str: string) {
    const encoder = new TextEncoder();
    return encoder.encode(str);
  }

  uintArrayToString(uintArray: Uint16Array) {
    const decoder = new TextDecoder();
    return decoder.decode(uintArray);
  }

  isCompleteMsg(msg: string): boolean {
    const trimmedMsg = msg.trim();

    if (trimmedMsg.length === 0) {
      return false;
    }
    console.log("trimmedMsg==>>>", msg);
    const msgData = msg.endsWith("\r\n");
    // return trimmedMsg[trimmedMsg.length - 1] === ">";
    return msgData === true;
  }
}
